<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h6>
                    {{ $t('collaborationPlans.profitSharingTitle') }}
                </h6>
                <div v-if="plan.withIntervals == true">
                    <div class="p-grid" v-for="(interval, index) in plan.intervals" :key="'interval' + index">
                        <div v-if="interval.endValue != 0" class="p-col-10 p-md-2">
                            {{ $t('collaborationPlans.from') }}
                            {{ interval.startValue }}
                            {{ $t('collaborationPlans.to') }}
                            {{ interval.endValue }}
                        </div>
                        <div v-else class="p-col-10 p-md-2">
                            {{ $t('collaborationPlans.over') }}
                            {{ interval.startValue }}
                        </div>
                        <div class="p-col-2 p-md-3">{{ interval.percValue }}%</div>
                    </div>
                </div>
                <div v-else>
                    <div class="p-grid" v-for="(product, index) in plan.products" :key="'product' + index">
                        <div class="p-col-10 p-md-2">
                            {{ $t('collaborationPlans.forProduct') }}
                            {{ product.name }}
                        </div>
                        <div class="p-col-2 p-md-3">{{ product.percValue }}%</div>
                    </div>
                </div>
                <h6>
                    <Fieldset :legend="$t('collaborationPlans.shopsTitle')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                        <DataTable :value="shops" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll">
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                            <Column field="code" :header="$t('system.code')" :sortable="true"></Column>
                            <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                                <template #body="{ data }">
                                    <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                                </template>
                            </Column>
                        </DataTable>
                    </Fieldset>
                </h6>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../../service/BoApi';

export default {
    name: 'palnDetails',
    props: {
        planId: {
            type: String,
            required: true,
            shops: [],
        },
    },
    data() {
        return {
            BoApi: new BoApi(),
            plan: {},
        };
    },
    mounted() {
        this.BoApi.getCollaborationPlan(this.planId)
            .then((response) => {
                this.plan = response.data.plan;
                this.shops = response.data.shops;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
};
</script>