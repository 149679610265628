<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.collaborationPlan') }}</h5>
                <DataTable :value="items" v-model:expandedRows="expandedRows" :rows="perPage" :paginator="true" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-2 p-text-left"><Button :label="$t('buttons.showHistory')" class="p-button-raised p-button-warning" @click="goto('collaborationPlanHistory')" /></div>

                            <div class="p-col-10 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(addPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column field="description" :header="$t('general.description')"></Column>
                    <Column field="type" :header="$t('collaborationPlans.type')">
                        <template #body="{ data }">
                            {{ data.withIntervals == true ? $t('collaborationPlans.withIntervals') : $t('collaborationPlans.withProducts') }}
                        </template>
                    </Column>
                    <Column field="decontAmount" :header="$t('collaborationPlans.amountUsed')" :sortable="true"></Column>
                    <Column field="createDate" :header="$t('general.createDate')" :sortable="true">
                        <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.createDate) }} </template></Column
                    >
                    <Column field="createBy" :header="$t('collaborationPlans.createdBy')" :sortable="true"></Column>
                    <template #expansion="slotProps">
                        <PlanDetails :planId="slotProps.data.id"></PlanDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../../service/BoApi';
import { uiSettings } from '../../../settings/uiSettings';
import PlanDetails from './Components/PlanDetails.vue';
export default {
    components: {
        PlanDetails,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'collaborationPlan',
            expandedRows: [],
        };
    },
    computed: {
        addPage() {
            return this.crtRoute + '/add';
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    mounted() {
        this.BoApi.getCollaborationPlans()
            .then((response) => {
                this.items = response.data;
                this.loading = false;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
};
</script>